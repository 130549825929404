






import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class TextDecimal extends Vue {
  public name = "TextDecimal";

  @Prop({ default: () => "span", type: String })
  protected componentTag!: any;

  @Prop({ default: () => 0, type: Number })
  protected value!: number;

  protected get formatValue(): string {
    return this.value?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
  }
}
