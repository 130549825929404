import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ILivePayPayment } from "@/interfaces/ILivePayPayment";

const URL = "live-pay";

@Component
export default class LivePayRequestMixin extends Vue {
  public livePayPayment: ILivePayPayment | null = null;
  public livePayPaymentLoading = false;
  public livePayPaymentSuccess = false;
  public livePayPaymentError: Record<any, any> | null = null;

  public async fetchLivePayPayment(livePayPaymentId: number): Promise<void> {
    this.livePayPaymentLoading = true;
    this.livePayPaymentSuccess = false;
    this.livePayPaymentError = null;
    return await axios
      .get(`${URL}/payment/${livePayPaymentId}`)
      .then((response: AxiosResponse) => {
        this.livePayPayment = response.data;
        this.livePayPaymentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.livePayPaymentError = error.response?.data;
      })
      .finally(() => {
        this.livePayPaymentLoading = false;
      });
  }
}
