import { Component, Vue } from "vue-property-decorator";
import { IFibuFixTransactionRecordDto } from "@/interfaces/IFibuFixTransactionRecordDto";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";
import { PaymentMethodEnum } from "@/enums/PaymentMethodEnum";

const URL = "/fibu-fix/records";

@Component
export default class FibuFixTransactionRequestMixin extends Vue {
  public fibuFixTransactions: Array<IFibuFixTransactionRecordDto> = [];
  public fibuFixTransactionsLoading = false;
  public fibuFixTransactionsSuccess = false;
  public fibuFixTransactionsError: Record<any, any> | null = null;

  public async getFibuFixTransactions(methodType: number, paymentId: number): Promise<void> {
    this.fibuFixTransactionsLoading = true;
    this.fibuFixTransactionsSuccess = false;
    this.fibuFixTransactionsError = null;

    return await axios
      .get(`${URL}/${methodType}/${paymentId}`)
      .then((response: AxiosResponse) => {
        this.fibuFixTransactions = response.data;
        this.fibuFixTransactionsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.fibuFixTransactionsError = error.response?.data;
      })
      .finally(() => {
        this.fibuFixTransactionsLoading = false;
      });
  }

  public fibuFixResendTransactionLoading = false;
  public fibuFixResendTransactionSuccess = false;
  public fibuFixResendTransactionError: Record<any, any> | null = null;

  public async resendFibuFixTransactions(paymentId: number, paymentMethod: PaymentMethodEnum | null = null): Promise<void> {
    this.fibuFixResendTransactionLoading = true;
    this.fibuFixResendTransactionSuccess = false;
    this.fibuFixResendTransactionError = null;

    let url = `/payments/resend-fibu-fix-transaction/${paymentId}`;

    if (paymentMethod == PaymentMethodEnum.LIVE_PAY) {
      url = `/live-pay/resend-fibu-fix-transaction/${paymentId}`;
    }

    return await axios
      .post(url)
      .then((response: AxiosResponse) => {
        this.fibuFixResendTransactionSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.fibuFixResendTransactionError = error.response?.data;
      })
      .finally(() => {
        this.fibuFixResendTransactionLoading = false;
      });
  }

  public fibuFixErrorsLoading = false;
  public fibuFixErrorsSuccess = false;
  public fibuFixErrorsError = null;
  public fibuFixErrors = [];

  public async getFibuFixErrors(): Promise<void> {
    this.fibuFixErrorsLoading = true;
    this.fibuFixErrorsSuccess = false;
    this.fibuFixErrorsError = null;

    return await axios
      .get(`/fibu-fix/records/errors`)
      .then((response: AxiosResponse) => {
        this.fibuFixErrorsSuccess = true;
        this.fibuFixErrors = response.data;
      })
      .catch((error: AxiosError) => {
        this.fibuFixErrorsError = error.response?.data;
      })
      .finally(() => {
        this.fibuFixErrorsLoading = false;
      });
  }
}
