



































import { Component, Prop, Vue } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import Validation from "@/components/Validation.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import moment from "moment";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: {
    FscSimpleCard,
    SaveButton,
    AbortButton,
    Validation,
    Datepicker,
  },
})
export default class EducationEventNoteForm extends Vue {
  public name = "EducationEventNoteForm";

  @Prop()
  public noteData: any;

  @Prop()
  public loading: any;

  public mounted() {
    this.$emit("on-mounted");
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }

  protected onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.$emit("on-submit");
    this.$v.$reset();
  }

  protected onClose(): void {
    this.$emit("on-close");
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      noteData: {
        note: { required },
      },
    };
  }
}
