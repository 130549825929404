











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { namespace } from "vuex-class";

const ExternalServicesModule = namespace("external-services");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
  },
})
export default class ExtenalServicePreview extends Vue {
  public name = "ExtenalServicePreview";

  @ExternalServicesModule.Action("findOne")
  public findExternalServiceById: any;

  @ExternalServicesModule.Getter("getDataItem")
  public externalServiceById: any;

  @Prop()
  public externalService!: any;

  @Prop({ type: String })
  public createdByName!: string;

  public licenseClass = "";

  protected theoryColumns = [
    {
      label: this.$t("event_types.basic_tl"),
      key: "basicTheoryLessons",
    },
    {
      label: this.$t("event_types.class_specific"),
      key: "classSpecificTheoryLessons",
    },
  ];

  protected praxisColumns = [
    {
      label: this.$t("event_types.lessons_hours"),
      key: "drivingLessons",
    },
    {
      label: this.$t("event_types.lessons_minutes"),
      key: "drivingLessonsMinutes",
    },
    {
      label: this.$t("event_types.cross_country_hours"),
      key: "crossCountryLesson",
    },
    {
      label: this.$t("event_types.cross_country_minutes"),
      key: "crossCountryLessonMinutes",
    },
    {
      label: this.$t("event_types.motorway_hours"),
      key: "motorwayLessons",
    },
    {
      label: this.$t("event_types.motorway_minutes"),
      key: "motorwayLessonsMinutes",
    },
    {
      label: this.$t("event_types.nighttime_hours"),
      key: "nightLessons",
    },
    {
      label: this.$t("event_types.nighttime_minutes"),
      key: "nightLessonsMinutes",
    },
    {
      label: this.$t("event_types.manual_hours"),
      key: "manualTransmissionLessons",
    },
    {
      label: this.$t("event_types.manual_minutes"),
      key: "manualTransmissionLessonsMinutes",
    },
    {
      label: this.$t("event_types.b197_hours"),
      key: "b197TestLessons",
    },
    {
      label: this.$t("event_types.b197_minutes"),
      key: "b197TestLessonsMinutes",
    },
    {
      label: this.$t("event_types.instruction_hours"),
      key: "instructionLessons",
    },
    {
      label: this.$t("event_types.instruction_minutes"),
      key: "instructionLessonsMinutes",
    },
    {
      label: this.$t("event_types.basic_task_hours"),
      key: "basicDrivingTask",
    },
    {
      label: this.$t("event_types.basic_task_minutes"),
      key: "basicDrivingTaskMinutes",
    },
    {
      label: this.$t("event_types.simulator_hours"),
      key: "simulatorLessons",
    },
    {
      label: this.$t("event_types.simulator_minutes"),
      key: "simulatorLessonsMinutes",
    },
  ];

  public async mounted(): Promise<void> {
    if (this.externalService) {
      this.licenseClass = this.externalService.licenseClass;
      await this.findExternalServiceById({
        id: this.externalService.id,
        resource: "external-services-packs",
      });
    }
  }

  @Watch("externalService")
  public onExternalServiceChange(service: any) {
    if (service) {
      this.licenseClass = service.licenseClass;
      this.findExternalServiceById({
        id: service.id,
        resource: "external-services-packs",
      });
    }
  }
}
