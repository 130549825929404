



































































import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import Datepicker from "@/components/Datepicker.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { IProduct } from "@/interfaces/IProduct";
import moment from "moment";
import _ from "lodash";

@Component({
  components: {
    SaveButton,
    AbortButton,
    Validation,
    FscInputDecimal,
    Datepicker,
    FscMultiselect,
    FscSimpleCard,
  },
})
export default class BasicFeeAndLearningMaterialForm extends Vue {
  public name = "BasicFeeAndLearningMaterialForm";

  private studentEducation = null;
  private date = null;
  private product = null;
  private amount = null;
  private costBearer: IBasicNamedDTO | null = null;

  @Prop({ type: Array, required: true })
  public studentEducations!: Array<any>;

  @Prop({ type: Number, required: true })
  public studentId!: number;

  @Prop({ type: Array, required: true })
  public products!: Array<any>;

  @Prop({ type: Function, required: true })
  public filterProducts!: (options: { licenseClass: string; studentId: number }) => Promise<void>;

  @Prop({ type: Array, required: true, default: () => [] })
  public prices!: Array<any>;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  @Prop({ type: Function, required: true })
  public fetchPricesByStudentEducation!: (educationId: number) => Promise<void>;

  @Prop({ type: String, required: true })
  public headerLabel!: string;

  @Validations()
  public validations(): Record<string, any> {
    return {
      studentEducation: { required },
      date: { required },
      product: { required },
    };
  }

  private onClose(): void {
    this.$emit("on-close");
  }

  public onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }
    this.$emit("on-submit", {
      studentEducation: this.studentEducation,
      date: this.date,
      amount: this.amount,
      product: this.product,
    });
  }

  private async studentEducationOnSelect(studentEducation: any): Promise<void> {
    this.costBearer = studentEducation.costBearer;
    await this.fetchPricesByStudentEducation(studentEducation.educationId);
    await this.filterProducts({
      licenseClass: studentEducation.licenseClass,
      studentId: this.studentId,
    });
  }

  public resetFields(): void {
    this.studentEducation = null;
    this.date = null;
    this.amount = null;
    this.product = null;
    this.costBearer = null;
    this.$v.$reset();
  }

  protected onProductSelect(product: IProduct): void {
    const price = _.find(this.prices, (price: any) => {
      return product.id === price.product.id;
    });
    if (price) {
      this.amount = price.priceInEur;
    }
  }

  public mounted(): void {
    this.$watch(
      "studentEducations",
      (studentEducations: any) => {
        if (studentEducations.length === 1) {
          this.studentEducation = studentEducations[0];
          this.studentEducationOnSelect(studentEducations[0]);
        }
      },
      { immediate: true, deep: true }
    );

    this.$watch(
      "products",
      (products: any) => {
        if (products.length === 1) {
          this.product = products[0];
          this.onProductSelect(products[0]);
        }
      },
      { immediate: true, deep: true }
    );
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }
}
