var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',{staticClass:"h-100"},[_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.license_class", 1))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.formData.studentEducation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.studentEducations,"label":"licenseClass"},on:{"select":_vm.onSelectStudentEducation},model:{value:(_vm.formData.studentEducation),callback:function ($$v) {_vm.$set(_vm.formData, "studentEducation", $$v)},expression:"formData.studentEducation"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("calendar.form_date", 1))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.formData.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('datepicker',{attrs:{"input-class":{ 'is-invalid': invalid },"initial-date":_vm.initialDate},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.product", 1))+" "+_vm._s(_vm.$t("user.optional"))+" ")]),_c('fsc-multiselect',{attrs:{"placeholder":_vm.$t('calendar.option_placeholder'),"deselect-label":_vm.$t('calendar.enter_remove'),"select-label":_vm.$t('calendar.enter_placeholder'),"options":_vm.products,"label":"name","track-by":"id","clearable":true},on:{"remove":_vm.onRemove},model:{value:(_vm.formData.product),callback:function ($$v) {_vm.$set(_vm.formData, "product", $$v)},expression:"formData.product"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("question.free_text"))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.formData.freeText},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.amountInEurDisabled},model:{value:(_vm.formData.freeText),callback:function ($$v) {_vm.$set(_vm.formData, "freeText", $$v)},expression:"formData.freeText"}})}}])}),_c('div',{staticClass:"font-size-14 text-tuatara-500 d-flex justify-content-end mt-2"},[_vm._v(" "+_vm._s(_vm.$t("general.text_length_limited", { count: _vm.freeTextLength }))+" ")])],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("students.amount", 1))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.formData.priceInEur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('FscInputDecimal',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.amountInEurDisabled},model:{value:(_vm.formData.priceInEur),callback:function ($$v) {_vm.$set(_vm.formData, "priceInEur", $$v)},expression:"formData.priceInEur"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.vat"))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.formData.vat},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"placeholder":_vm.$t('calendar.option_placeholder'),"deselect-label":_vm.$t('calendar.enter_remove'),"select-label":_vm.$t('calendar.enter_placeholder'),"label":"name","track-by":"id","options":_vm.vat,"disabled":_vm.vatDisabled},model:{value:(_vm.formData.vat),callback:function ($$v) {_vm.$set(_vm.formData, "vat", $$v)},expression:"formData.vat"}})}}])})],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btn-container mr-0"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onClose()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('general.save'),"loading":_vm.loading},on:{"click":function($event){return _vm.onSubmit()}}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }