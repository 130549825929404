


















































import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import { toDateTime } from "@/utils/DateTime";

@Component({
  components: { TextDecimal, FscHorizontalTable, FscSimpleCard },
})
export default class EducationEventDetails extends Vue {
  public name = "EducationEventDetails";

  @Prop({ required: false })
  public item!: any;

  @Prop({ required: false, default: () => "car" })
  public icon!: string;

  @Prop({ required: false, default: () => "fat" })
  public iconPrefix!: string;

  @Prop({ required: false, default: () => "" })
  public iconClass!: string;

  @Prop({ required: false, default: () => "" })
  public img!: string;

  @Prop({ required: false, default: () => "" })
  public subTitleClass!: string;

  @Prop({ required: false, type: Boolean })
  public loading!: boolean;

  @Prop({ required: false, type: String })
  public createdOn?: string;

  @Prop({ required: false, type: String })
  public bookedOn!: string;

  @Prop({ required: false, type: Number })
  public priceInEur!: number;

  @Prop({ required: false, type: String })
  public title!: string;

  @Prop({ required: false, type: String })
  public subTitle!: string;

  @Prop({ required: false })
  public idTitle!: any;

  @Prop({ type: String })
  public createdByName!: string;

  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student.name",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "time",
    },
    {
      label: this.$tc("general.duration", 1),
      key: "duration",
    },
    {
      label: this.$tc("calendar.vehicle", 1),
      key: "vehicle.brandAndModel",
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "instructor",
    },
  ];

  public formatDateTime(dateTime: string | null): string {
    return toDateTime(dateTime);
  }
}
