




















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IEducationEvent } from "@/interfaces/IEducationEvent";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { toDateTime } from "@/utils/DateTime";
import { IPracticalExam } from "@/interfaces/Exam/IPracticalExam";
import EducationEventDetails from "@/views/Student/EducationEvent/Details/EducationEventDetails.vue";
import { mixins } from "vue-class-component";
import EducationEventRequestMixin from "@/mixins/Request/EducationEventRequestMixin";
import PriceMixin from "@/mixins/PriceMixin";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";

@Component({
  components: { EducationEventDetails, FscHorizontalTable, FscSimpleCard },
})
export default class PracticalExamDetails extends mixins(EducationEventRequestMixin, PriceMixin) {
  public name = "PracticalExamDetails";

  @Prop({ required: true })
  public educationEvent!: any;

  @Prop({ required: true })
  public item!: IPracticalExam;

  @Prop({ required: false, type: Boolean })
  public loading!: boolean;

  @Prop()
  public student!: any;

  @Prop({ type: String })
  public createdByName!: string;

  protected sourceSignature: any = null;

  protected columns = [
    {
      label: this.$t("event_types.product_booked"),
      key: "productBookingId",
    },
    {
      label: this.$t("event_types.tuv_booked"),
      key: "tuvProductBookingId",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student.name",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "practicalExamTime",
    },
    {
      label: this.$tc("sidebar.instructors", 1),
      key: "instructor",
    },
    {
      label: this.$t("general.signature"),
      key: "signature",
    },
  ];

  public formatDateTime(dateTime: string | null): string {
    return toDateTime(dateTime);
  }

  protected get practicalExam(): any {
    return (
      this.item?.studentPracticalExams?.find(
        (x: any) => x.student.id === this.educationEvent.student.id && x.licenseClass === this.educationEvent.licenseClass
      ) || null
    );
  }

  protected get instructor(): string {
    return this.practicalExam && this.practicalExam.instructor ? `${this.practicalExam.instructor.firstName} ${this.practicalExam.instructor.lastName}` : "-";
  }

  protected get productBooking(): any {
    if (this.practicalExam && this.practicalExam.productBookingId) {
      return this.practicalExam.productBookingId;
    }
    return "";
  }

  protected get tuvBooking(): any {
    return this.practicalExam && this.practicalExam.tuvProductBookingId ? this.practicalExam.tuvProductBookingId : "";
  }

  protected get bookedProduct(): any {
    return this.productBooked && this.productBooked.priceInEur ? this.productBooked.priceInEur : "";
  }

  protected get tuvProduct(): any {
    return this.tuvBooked && this.tuvBooked.priceInEur ? this.tuvBooked.priceInEur : "";
  }

  @Watch("item")
  public onItemChange(item: any): void {
    if (item && item.studentPracticalExams.length > 0) {
      item.studentPracticalExams.map(async (exam: any) => {
        if (exam.student.id === this.student?.id && exam.licenseClass === this.educationEvent.licenseClass) {
          if (exam.studentSignatureDocumentId) {
            return await axios({
              method: "get",
              url: `/student-documents/${exam.studentSignatureDocumentId}`,
              responseType: "arraybuffer",
            })
              .then(
                (response: AxiosResponse) => {
                  const mimeType = response.headers["content-type"].toLowerCase();
                  const blob = response.data;
                  this.sourceSignature =
                    "data:" +
                    mimeType +
                    ";base64," +
                    //@ts-ignore
                    new Buffer(blob, "binary").toString("base64");
                },
                () => {
                  console.log("Unavailable signature");
                }
              )
              .finally(() => {
                //
              });
          } else {
            this.sourceSignature = null;
          }
        }
      });
    }
    if (item && item.booked) {
      this.productBooking ? this.fetchProductBooked(this.productBooking) : (this.productBooked = null);
      this.tuvBooking ? this.fetchTuvBooked(this.tuvBooking) : (this.tuvBooked = null);
    }
    this.productBooked = null;
    this.tuvBooked = null;
  }
}
