




















































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import PriceMixin from "@/mixins/PriceMixin";
import { namespace } from "vuex-class";
import { formatStudentName } from "@/utils/NameUtil";

const StudentBookedProductModule = namespace("student-booked-product");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
  },
})
export default class ProductBookingPreview extends PriceMixin {
  public name = "ProductBookingPreview";

  @StudentBookedProductModule.Action("findOne")
  public findBookedProductById: any;

  @StudentBookedProductModule.Getter("getDataItem")
  public bookedProduct: any;

  @Prop()
  public basicAmount!: any;

  @Prop({ type: String })
  public createdByName!: string;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountToPay",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$t("general.vat"),
      key: "vat",
    },
  ];

  public mounted(): void {
    if (this.basicAmount.id) {
      this.findBookedProductById({
        id: this.basicAmount.id,
        resource: "student-booked-product",
      });
    }
  }

  public get studentFullName() {
    if (this.basicAmount.student) {
      return formatStudentName(this.basicAmount.student.firstName, this.basicAmount.student.lastName);
    }
    return "";
  }

  public get percentageAmount() {
    if (this.bookedProduct.vat) {
      return `${this.bookedProduct.vat.percentageAmount} %`;
    }
    return "";
  }

  public get licenseClass() {
    if (this.basicAmount.licenseClass) {
      return this.basicAmount.licenseClass;
    }
    return "";
  }

  @Watch("basicAmount")
  public onTheoryLessonIdChange(basicAmount: any): void {
    if (basicAmount) {
      this.findBookedProductById({
        id: basicAmount.id,
        resource: "student-booked-product",
      });
    }
  }

  protected get title(): string {
    return this.basicAmount?.description || "";
  }
}
