<template>
  <iframe :srcdoc="src" class="border-0 h-100 w-100" />
</template>

<script>
export default {
  name: "HtmlViewer",
  props: {
    src: {
      type: String,
    },
  },
};
</script>
