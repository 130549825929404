












































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { formatStudentName } from "@/utils/NameUtil";

const StudentNoteModule = namespace("student-note");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
  },
})
export default class EducationNotesPreview extends Vue {
  public name = "EducationNotesPreview";

  @StudentNoteModule.Action("findOne")
  public findNoteById: any;

  @StudentNoteModule.Getter("getDataItem")
  public studentNote: any;

  @Prop({ type: String })
  public createdByName!: string;

  @Prop()
  public note!: any;

  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "note",
    },
  ];

  public mounted(): void {
    if (this.note) {
      this.findNoteById({
        id: this.note.id,
        resource: "student-note",
      });
    }
  }

  public get studentFullName() {
    if (this.studentNote.student) {
      return formatStudentName(this.studentNote.student.firstName, this.studentNote.student.lastName);
    }
    return "";
  }

  @Watch("note")
  public onNoteChange(note: any) {
    if (note) {
      this.findNoteById({
        id: note.id,
        resource: "student-note",
      });
    }
  }
}
