export interface CurrencyInputValue {
  number: number | null;
  formatted: string | null;
}

export interface NumberRange {
  min?: number;
  max?: number;
}

export enum CurrencyDisplay {
  symbol = "symbol",
  narrowSymbol = "narrowSymbol",
  code = "code",
  name = "name",
  hidden = "hidden",
}

export interface CurrencyInputOptions {
  locale?: string;
  currency: string;
  currencyDisplay?: CurrencyDisplay;
  exportValueAsInteger?: boolean;
  hideCurrencySymbolOnFocus?: boolean;
  hideGroupingSeparatorOnFocus?: boolean;
  hideNegligibleDecimalDigitsOnFocus?: boolean;
  precision?: number;
  autoDecimalDigits?: boolean;
  autoSign?: boolean;
  valueRange?: NumberRange;
  useGrouping?: boolean;
}

export interface UseCurrencyInput {
  inputRef: any;
  formattedValue: any;
  setValue: (number: number | null) => void;
  setOptions: (options: CurrencyInputOptions) => void;
}
