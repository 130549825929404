export const PENDING_CUSTOMER_APPROVAL = "PENDING_CUSTOMER_APPROVAL";
export const PENDING_SUBMISSION = "PENDING_SUBMISSION";
export const SUBMITTED = "SUBMITTED";
export const CONFIRMED = "CONFIRMED";

export const PAID_OUT = "PAID_OUT";

export const CANCELLED = "CANCELLED";
export const CUSTOMER_APPROVAL_DENIED = "CUSTOMER_APPROVAL_DENIED";
export const FAILED = "FAILED";
export const CHARGED_BACK = "CHARGED_BACK";

const PENDING_COLOR = "#F9B342";
const PAID_OUT_COLOR = "#17A533";
const DECLINED_COLOR = "#DC0C23";

export const COLORS_BY_STATUS = {
  PENDING_CUSTOMER_APPROVAL: PENDING_COLOR,
  PENDING_SUBMISSION: PENDING_COLOR,
  SUBMITTED: PENDING_COLOR,
  CONFIRMED: PENDING_COLOR,
  PAID_OUT: PAID_OUT_COLOR,
  CANCELLED: DECLINED_COLOR,
  CUSTOMER_APPROVAL_DENIED: DECLINED_COLOR,
  FAILED: DECLINED_COLOR,
  CHARGED_BACK: DECLINED_COLOR,
};

export default [
  {
    key: PENDING_CUSTOMER_APPROVAL,
    icon: "plus",
    iconPrefix: "fas",
    color: PENDING_COLOR,
  },
  {
    key: PENDING_SUBMISSION,
    icon: "arrow-down",
    iconPrefix: "fas",
    color: PENDING_COLOR,
  },
  {
    key: SUBMITTED,
    icon: "check",
    iconPrefix: "fas",
    color: PENDING_COLOR,
  },
  {
    key: CONFIRMED,
    icon: "check",
    iconPrefix: "fas",
    color: PENDING_COLOR,
  },
  {
    key: PAID_OUT,
    icon: "coins",
    iconPrefix: "fas",
    color: PAID_OUT_COLOR,
  },
  {
    key: CANCELLED,
    icon: "x",
    iconPrefix: "fas",
    color: DECLINED_COLOR,
  },
  {
    key: CUSTOMER_APPROVAL_DENIED,
    icon: "x",
    iconPrefix: "fas",
    color: DECLINED_COLOR,
  },
  {
    key: FAILED,
    icon: "x",
    iconPrefix: "fas",
    color: DECLINED_COLOR,
  },
  {
    key: CHARGED_BACK,
    icon: "x",
    iconPrefix: "fas",
    color: DECLINED_COLOR,
  },
];
