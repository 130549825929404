var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"h-100 overflow-y-auto",attrs:{"show":_vm.paymentLoading}},[_c('fsc-simple-card',{staticClass:"h-100 overflow-y-auto",attrs:{"header-class":"justify-content-end flex-row py-20"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.hasPermission)?_c('div',{staticClass:"d-flex w-100 flex-wrap"},[_c('CreateButton',{on:{"click":_vm.onCreate}}),_c('RemoveButton',{on:{"click":_vm.onRemove}}),_c('EditButton',{on:{"click":_vm.onEdit}})],1):_vm._e(),_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('font-awesome-icon',{attrs:{"icon":['fat', 'coin'],"size":"lg","fixed-width":""}})],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.paymentItem && _vm.paymentItem.date)?[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("general.created_on_2")))]),_vm._v(" "+_vm._s(_vm._f("formatDateAndHour")(_vm.createdOn))+" "+_vm._s(_vm.$t("calendar.clock"))+" ")]:_vm._e(),(_vm.createdByName)?[_c('span',{staticClass:"d-block"},[_c('b',[_vm._v(_vm._s(_vm.$t("sidebar.instructors")))]),_vm._v(" "+_vm._s(_vm.createdByName)+" ")])]:_vm._e()]},proxy:true}])},[_c('h5',{staticClass:"font-weight-bold pb-20"},[_vm._v(_vm._s(_vm.title))]),(_vm.subtitle)?_c('h5',{staticClass:"pb-20"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),_c('fsc-horizontal-table',{attrs:{"columns":_vm.columns,"item":_vm.paymentItem},scopedSlots:_vm._u([{key:"cell(amountToPay)",fn:function(props){return [_c('div',{staticClass:"d-flex w-100 align-items-baseline"},[_c('span',{staticClass:"mr-10",staticStyle:{"font-size":"x-large"}},[_vm._v(_vm._s(props.column.label))]),(_vm.currentMode === 'edit')?_c('Validation',{staticClass:"w-100 font-weight-normal",attrs:{"validations":_vm.$v.editAmountInEur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('FscInputDecimal',{directives:[{name:"init",rawName:"v-init",value:({
                bindTo: 'editAmountInEur',
                value: props.item.amountInEur,
              }),expression:"{\n                bindTo: 'editAmountInEur',\n                value: props.item.amountInEur,\n              }"}],class:{ 'is-invalid': invalid },model:{value:(_vm.editAmountInEur),callback:function ($$v) {_vm.editAmountInEur=$$v},expression:"editAmountInEur"}})}}],null,true)}):_c('span',{staticClass:"font-weight-normal",staticStyle:{"font-size":"x-large"},domProps:{"innerHTML":_vm._s(_vm.reformatPriceInEur(props.item.amountInEur))}})],1)]}},{key:"cell(student)",fn:function(props){return [_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(props.column.label))]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.studentFullName)+" ")])]}},{key:"cell(licenseClass)",fn:function(props){return [_c('div',{staticClass:"d-flex w-100 align-items-baseline"},[_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(props.column.label))]),(_vm.currentMode === 'edit')?_c('Validation',{staticClass:"w-100 font-weight-normal",attrs:{"validations":_vm.$v.editStudentEducationLicenseClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return _c('fsc-multiselect',{directives:[{name:"init",rawName:"v-init",value:({
                bindTo: 'editStudentEducationLicenseClass',
                value: _vm.licenseClass,
              }),expression:"{\n                bindTo: 'editStudentEducationLicenseClass',\n                value: licenseClass,\n              }"}],class:{ multiselect__invalid: invalid },attrs:{"options":_vm.studentEducations},model:{value:(_vm.editStudentEducationLicenseClass),callback:function ($$v) {_vm.editStudentEducationLicenseClass=$$v},expression:"editStudentEducationLicenseClass"}})}}],null,true)}):_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.licenseClass)+" ")])],1)]}},{key:"cell(vat)",fn:function(props){return [_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(props.column.label))]),(_vm.currentMode === 'edit')?_c('Validation',{staticClass:"w-100 font-weight-normal",attrs:{"validations":_vm.$v.editVat},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return _c('fsc-multiselect',{directives:[{name:"init",rawName:"v-init",value:({
              bindTo: 'editVat',
              value: _vm.percentageAmount,
            }),expression:"{\n              bindTo: 'editVat',\n              value: percentageAmount,\n            }"}],class:{ multiselect__invalid: invalid },attrs:{"options":_vm.vats,"label":"name","track-by":"id","show-labels":false},model:{value:(_vm.editVat),callback:function ($$v) {_vm.editVat=$$v},expression:"editVat"}})}}],null,true)}):_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.percentageAmount)+" ")])]}},{key:"cell(paymentMethod)",fn:function(props){return [_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(props.column.label))]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.paymentMethod)+" ")])]}},{key:"cell(date)",fn:function(props){return [_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(props.column.label))]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item ? props.item.date : null))+" ")])]}},{key:"cell(hasFibuFixTransaction)",fn:function(props){return [_c('span',{staticClass:"mr-10"},[_vm._v(_vm._s(props.column.label))]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(props.item.hasFibuFixTransaction ? _vm.$t("general.yes") : _vm.$t("general.no"))+" ")])]}},{key:"cell(actions)",fn:function(){return [(_vm.showAdditionalFields)?_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("students.amount"))+" * ")]),_c('Validation',{staticClass:"font-weight-normal",attrs:{"validations":_vm.$v.newAmountInEur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return _c('FscInputDecimal',{class:{ 'is-invalid': invalid },model:{value:(_vm.newAmountInEur),callback:function ($$v) {_vm.newAmountInEur=$$v},expression:"newAmountInEur"}})}}],null,false,3894927097)})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.license_class", 1))+" * ")]),_c('Validation',{staticClass:"font-weight-normal",attrs:{"validations":_vm.$v.newStudentEducationLicenseClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.studentEducationsForCreate,"label":"mainLicenseClass","reduce":function (o) { return o.id; }},model:{value:(_vm.newStudentEducationLicenseClass),callback:function ($$v) {_vm.newStudentEducationLicenseClass=$$v},expression:"newStudentEducationLicenseClass"}})}}],null,false,3993876781)})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.vat"))+" * ")]),_c('Validation',{staticClass:"w-100 font-weight-normal",attrs:{"validations":_vm.$v.newVat},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.vats,"label":"name","track-by":"id","show-labels":false},model:{value:(_vm.newVat),callback:function ($$v) {_vm.newVat=$$v},expression:"newVat"}})}}],null,false,439077339)})],1)],1):_vm._e(),(_vm.showAction)?_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('div',{staticClass:"btn-container mr-0"},[_c('AbortButton',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onClose()}}}),_c('SaveButton',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('general.save')},on:{"click":function($event){return _vm.onSave()}}})],1)])],1):_vm._e()]},proxy:true}])}),_c('DeleteModal',{attrs:{"modalId":_vm.deleteModalId,"delete-action":_vm.okRemove}})],1),_c('FibuFixTransactionsModal',{attrs:{"fibufix-transactions-modal-id":_vm.fibufixTransactionsModalId,"fibufixItems":_vm.fibufixItems,"fibuFixTransactionsLoading":_vm.fibuFixTransactionsLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }