



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import Tinymce from "@/components/Editor/Tinymce.vue";

const MessagingModule = namespace("messaging");

@Component({
  components: {
    FscSimpleCard,
    Tinymce,
  },
})
export default class MessagePreview extends Vue {
  public name = "MessagePreview";

  @MessagingModule.Action("findOne")
  public findMessageById: any;

  @MessagingModule.Getter("getDataItem")
  public messageById: any;

  @MessagingModule.Getter("getIsLoading")
  public loading: any;

  @Prop()
  public message!: any;

  @Prop({ type: String })
  public createdByName!: string;

  public mounted(): void {
    if (this.message) {
      this.findMessageById({
        id: this.message.id,
        resource: "messages",
      });
    }
  }

  @Watch("message")
  public onNoteChange(msg: any) {
    if (msg) {
      this.findMessageById({
        id: msg.id,
        resource: "messages",
      });
    }
  }
}
