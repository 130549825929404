import { Component, Vue } from "vue-property-decorator";
//@ts-ignore
import { format } from "@/utils/money/utils.js";

@Component
export default class PriceMixin extends Vue {
  private moneySuffix = "€";

  public reformatPriceInEur(value: any): string {
    const moneyOption = {
      decimal: ",",
      thousands: "",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    };
    return value !== "" ? `${format(value, moneyOption)} ${this.moneySuffix}` : ``;
  }
}
