















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StudentDocumentModule = namespace("student-document");

@Component({})
export default class EducationDocumentPreview extends Vue {
  public name = "EducationDocumentPreview.vue";

  @StudentDocumentModule.Action("findStudentDocument")
  public findStudentDocument: any;

  @StudentDocumentModule.Getter("getMimeType")
  public mimeType: any;

  @StudentDocumentModule.Getter("getDataItem")
  public blob: any;

  @StudentDocumentModule.Getter("getIsLoading")
  public isLoading: any;

  @Prop()
  documentId: any;

  private visibleIframe = false;
  private visibleImg = false;
  private source = "";

  public created(): void {
    this.findStudentDocument(this.documentId);
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");

      if (this.mimeType.startsWith("image")) {
        this.visibleImg = true;
        this.visibleIframe = false;
      }

      if (this.mimeType === "application/pdf") {
        this.visibleIframe = true;
        this.visibleImg = false;
      }
    }
  }

  @Watch("documentId")
  public onStudentDocumentIdChange(documentId: any) {
    if (documentId) {
      this.findStudentDocument(this.documentId);
    }
  }
}
