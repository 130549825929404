import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";

const crudService = new CrudService();
const URL = "/products";

@Component
export default class ProductRequestMixin extends Vue {
  protected learningMaterialProducts = [];
  protected learningMaterialProductsSuccess = false;
  protected learningMaterialProductsError: Record<any, any> | null = null;
  protected learningMaterialProductsLoading = false;

  protected basicFeeProducts = [];
  protected basicFeeProductsSuccess = false;
  protected basicFeeProductsError: Record<any, any> | null = null;
  protected basicFeeProductsLoading = false;

  protected productsByStudentAndLicenseClass = [];
  protected productsByStudentAndLicenseClassSuccess = false;
  protected productsByStudentAndLicenseClassError: Record<any, any> | null = null;
  protected productsByStudentAndLicenseClassLoading = false;

  public async fetchLearningMaterialProducts(options: { studentId: number; licenseClass: string }): Promise<void> {
    this.learningMaterialProductsSuccess = false;
    this.learningMaterialProductsError = null;
    this.learningMaterialProductsLoading = true;
    return await crudService
      .findAll({
        resource: `${URL}/learning-material-products?studentId=${options.studentId}&licenseClass=${options.licenseClass}`,
      })
      .then((response: AxiosResponse) => {
        this.learningMaterialProducts = response.data;
        this.learningMaterialProductsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.learningMaterialProductsError = error.response?.data;
      })
      .finally(() => {
        this.learningMaterialProductsLoading = false;
      });
  }

  public async fetchBasicFeeProducts(options: { studentId: number; licenseClass: string }): Promise<void> {
    this.basicFeeProductsSuccess = false;
    this.basicFeeProductsError = null;
    this.basicFeeProductsLoading = true;
    return await crudService
      .findAll({
        resource: `${URL}/basic-fee-products?studentId=${options.studentId}&licenseClass=${options.licenseClass}`,
      })
      .then((response: AxiosResponse) => {
        this.basicFeeProducts = response.data;
        this.basicFeeProductsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.learningMaterialProductsError = error.response?.data;
      })
      .finally(() => {
        this.basicFeeProductsLoading = false;
      });
  }

  public async fetchProductsByStudentAndLicenseClass(studentId: number, licenseClass: string): Promise<void> {
    this.productsByStudentAndLicenseClassSuccess = false;
    this.productsByStudentAndLicenseClassError = null;
    this.productsByStudentAndLicenseClassLoading = true;
    return await axios
      .get(`${URL}/driving-lesson-products?studentId=${studentId}&licenseClass=${licenseClass}`)
      .then((response: AxiosResponse) => {
        this.productsByStudentAndLicenseClass = response.data.map((product: any) =>
          product.standardProductId === 100 ? { ...product, isDefault: true } : { ...product, isDefault: false }
        );
        this.productsByStudentAndLicenseClassSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.productsByStudentAndLicenseClassError = error.response?.data;
      })
      .finally(() => {
        this.productsByStudentAndLicenseClassLoading = false;
      });
  }
}
