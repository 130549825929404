import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import { IDrivingLesson } from "@/interfaces/IDrivingLesson";

const crudService = new CrudService();
const URL = "/driving-lessons";

@Component
export default class DrivingLessonRequestMixin extends Vue {
  protected drivingLesson: IDrivingLesson | null = null;
  protected drivingLessonSuccess = false;
  protected drivingLessonError: Record<any, any> | null = null;
  protected drivingLessonLoading = false;

  public async fetchDrivingLesson(drivingLessonId: number): Promise<void> {
    this.drivingLessonLoading = true;
    this.drivingLessonSuccess = false;
    this.drivingLessonError = null;
    return await crudService
      .findOne({
        resource: `${URL}`,
        id: drivingLessonId,
      })
      .then((response: AxiosResponse) => {
        this.drivingLesson = response.data;
      })
      .catch((error: AxiosError) => {
        this.drivingLessonError = error.response?.data;
      })
      .finally(() => {
        this.drivingLessonLoading = false;
      });
  }
}
