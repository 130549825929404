import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import { ITheoryExam } from "@/interfaces/Exam/ITheoryExam";
import createCancelableRequest from "@/utils/axiosCancelable";

@Component
export default class TheoryExamRequestMixin extends Vue {
  protected theoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected theoryExam: ITheoryExam | null = null;
  protected theoryExamSuccess = false;
  protected theoryExamError: Record<any, any> | null = null;
  private theoryExamLoadingQueue = 0;
  public get theoryExamLoading() {
    return this.theoryExamLoadingQueue > 0;
  }

  public async fetchTheoryExam(theoryExamId: number): Promise<void> {
    this.theoryExamLoadingQueue++;
    this.theoryExamSuccess = false;
    this.theoryExamError = null;

    return await this.theoryExamCancelableRequest
      .makeRequest({
        method: "get",
        url: `/theory-exam/${theoryExamId}`,
      })
      .then((response: AxiosResponse) => {
        this.theoryExam = response.data;
        this.theoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.theoryExamError = error.response?.data;
      })
      .finally(() => {
        this.theoryExamLoadingQueue--;
      });
  }

  // Theory exam archive
  protected archiveTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected archiveTheoryExamSuccess = false;
  protected archiveTheoryExamError: Record<any, any> | null = null;
  private archiveTheoryExamLoadingQueue = 0;
  public get archiveTheoryExamLoading() {
    return this.archiveTheoryExamLoadingQueue > 0;
  }

  public async archiveTheoryExam(theoryExamId: number, archived: boolean): Promise<void> {
    this.archiveTheoryExamLoadingQueue++;
    this.archiveTheoryExamSuccess = false;
    this.archiveTheoryExamError = null;

    return await this.archiveTheoryExamCancelableRequest
      .makeRequest({
        method: "post",
        url: `/theory-exam/archive?id=${theoryExamId}&archived=${archived}`,
      })
      .then((response: AxiosResponse) => {
        this.archiveTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.archiveTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.archiveTheoryExamLoadingQueue--;
      });
  }

  // Create Theory exam
  protected createTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected createTheoryExamSuccess = false;
  protected createTheoryExamError: Record<any, any> | null = null;
  private createTheoryExamLoadingQueue = 0;
  public get createTheoryExamLoading() {
    return this.createTheoryExamLoadingQueue > 0;
  }

  public async createTheoryExam(data: any): Promise<void> {
    this.createTheoryExamLoadingQueue++;
    this.createTheoryExamSuccess = false;
    this.createTheoryExamError = null;

    return await this.createTheoryExamCancelableRequest
      .makeRequest({
        method: "post",
        url: `/theory-exam`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.createTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.createTheoryExamLoadingQueue--;
      });
  }

  // Update Theory exam
  protected updateTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected updateTheoryExamSuccess = false;
  protected updateTheoryExamError: Record<any, any> | null = null;
  private updateTheoryExamLoadingQueue = 0;
  public get updateTheoryExamLoading() {
    return this.updateTheoryExamLoadingQueue > 0;
  }

  public async updateTheoryExam(id: number, data: any): Promise<void> {
    this.updateTheoryExamLoadingQueue++;
    this.updateTheoryExamSuccess = false;
    this.updateTheoryExamError = null;

    return await this.updateTheoryExamCancelableRequest
      .makeRequest({
        method: "put",
        url: `/theory-exam/${id}`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.updateTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updateTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.updateTheoryExamLoadingQueue--;
      });
  }

  // Delete Theory exam
  protected deleteTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected deleteTheoryExamSuccess = false;
  protected deleteTheoryExamError: Record<any, any> | null = null;
  private deleteTheoryExamLoadingQueue = 0;
  public get deleteTheoryExamLoading() {
    return this.deleteTheoryExamLoadingQueue > 0;
  }

  public async deleteTheoryExam(id: number): Promise<void> {
    this.deleteTheoryExamLoadingQueue++;
    this.deleteTheoryExamSuccess = false;
    this.deleteTheoryExamError = null;

    return await this.deleteTheoryExamCancelableRequest
      .makeRequest({
        method: "delete",
        url: `/theory-exam/${id}`,
      })
      .then((response: AxiosResponse) => {
        this.deleteTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.deleteTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.deleteTheoryExamLoadingQueue--;
      });
  }

  // Delete Participants
  protected deleteParticipantsTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected deleteParticipantsTheoryExamSuccess = false;
  protected deleteParticipantsTheoryExamError: Record<any, any> | null = null;
  private deleteParticipantsTheoryExamLoadingQueue = 0;
  public get deleteParticipantsTheoryExamLoading() {
    return this.deleteParticipantsTheoryExamLoadingQueue > 0;
  }

  public async deleteParticipantsTheoryExam(id: number): Promise<void> {
    this.deleteParticipantsTheoryExamLoadingQueue++;
    this.deleteParticipantsTheoryExamSuccess = false;
    this.deleteParticipantsTheoryExamError = null;

    return await this.deleteParticipantsTheoryExamCancelableRequest
      .makeRequest({
        method: "delete",
        url: `/theory-exam/student-exam/${id}`,
      })
      .then((response: AxiosResponse) => {
        this.deleteParticipantsTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.deleteParticipantsTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.deleteParticipantsTheoryExamLoadingQueue--;
      });
  }

  // update Theory exam status
  protected updateTheoryExamStatusCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected updateTheoryExamStatusSuccess = false;
  protected updateTheoryExamStatusError: Record<any, any> | null = null;
  private updateTheoryExamStatusLoadingQueue = 0;
  public get updateTheoryExamStatusLoading() {
    return this.updateTheoryExamStatusLoadingQueue > 0;
  }

  public async updateTheoryExamStatus(data: any): Promise<void> {
    this.updateTheoryExamStatusLoadingQueue++;
    this.updateTheoryExamStatusSuccess = false;
    this.updateTheoryExamStatusError = null;

    return await this.updateTheoryExamStatusCancelableRequest
      .makeRequest({
        method: "put",
        url: `/theory-exam/update-exam-status`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.updateTheoryExamStatusSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updateTheoryExamStatusError = error.response?.data;
      })
      .finally(() => {
        this.updateTheoryExamStatusLoadingQueue--;
      });
  }

  // book Theory exam
  protected bookTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected bookTheoryExamSuccess = false;
  protected bookTheoryExamError: Record<any, any> | null = null;
  private bookTheoryExamLoadingQueue = 0;
  public get bookTheoryExamLoading() {
    return this.bookTheoryExamLoadingQueue > 0;
  }

  public async bookTheoryExam(theoryExamId: number): Promise<void> {
    this.bookTheoryExamLoadingQueue++;
    this.bookTheoryExamSuccess = false;
    this.bookTheoryExamError = null;

    return await this.bookTheoryExamCancelableRequest
      .makeRequest({
        method: "post",
        url: `/theory-exam/${theoryExamId}/book`,
      })
      .then((response: AxiosResponse) => {
        this.bookTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.bookTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.bookTheoryExamLoadingQueue--;
      });
  }

  // upload Theory exam protocol
  protected uploadProtocolTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected uploadProtocolTheoryExamSuccess = false;
  protected uploadProtocolTheoryExamError: Record<any, any> | null = null;
  private uploadProtocolTheoryExamLoadingQueue = 0;
  public get uploadProtocolTheoryExamLoading() {
    return this.uploadProtocolTheoryExamLoadingQueue > 0;
  }

  public async uploadProtocolTheoryExam(data: any): Promise<void> {
    this.uploadProtocolTheoryExamLoadingQueue++;
    this.uploadProtocolTheoryExamSuccess = false;
    this.uploadProtocolTheoryExamError = null;

    return await this.uploadProtocolTheoryExamCancelableRequest
      .makeRequest({
        method: "post",
        url: `/theory-exam/upload-protocol`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      })
      .then((response: AxiosResponse) => {
        this.uploadProtocolTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.uploadProtocolTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.uploadProtocolTheoryExamLoadingQueue--;
      });
  }

  // Theory exam Participant info
  protected participantInfoTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected participantInfoTheoryExamSuccess = false;
  protected participantInfoTheoryExamError: Record<any, any> | null = null;
  private participantInfoTheoryExamLoadingQueue = 0;
  protected participantInfoTheoryExamData = null;
  public get participantInfoTheoryExamLoading() {
    return this.participantInfoTheoryExamLoadingQueue > 0;
  }

  public async participantInfoTheoryExam(studentEducationId: number): Promise<void> {
    this.participantInfoTheoryExamLoadingQueue++;
    this.participantInfoTheoryExamSuccess = false;
    this.participantInfoTheoryExamError = null;

    return await this.participantInfoTheoryExamCancelableRequest
      .makeRequest({
        method: "get",
        url: `/student-educations/${studentEducationId}/fulfilled-requirements-theory`,
      })
      .then((response: AxiosResponse) => {
        this.participantInfoTheoryExamSuccess = true;
        this.participantInfoTheoryExamData = response.data;
      })
      .catch((error: AxiosError) => {
        this.participantInfoTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.participantInfoTheoryExamLoadingQueue--;
      });
  }

  // Boom Theory exam product
  protected bookTheoryExamProductCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected bookTheoryExamProductSuccess = false;
  protected bookTheoryExamProductError: Record<any, any> | null = null;
  private bookTheoryExamProductLoadingQueue = 0;
  public get bookTheoryExamProductLoading() {
    return this.bookTheoryExamProductLoadingQueue > 0;
  }

  public async bookTheoryExamProduct(studentEducationId: number, studentTheoryExamId: number): Promise<void> {
    this.bookTheoryExamProductLoadingQueue++;
    this.bookTheoryExamProductSuccess = false;
    this.bookTheoryExamProductError = null;

    return await this.bookTheoryExamProductCancelableRequest
      .makeRequest({
        method: "post",
        url: `student-booked-product/on-failed-exam/${studentEducationId}/${studentTheoryExamId}`,
      })
      .then((response: AxiosResponse) => {
        this.bookTheoryExamProductSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.bookTheoryExamProductError = error.response?.data;
      })
      .finally(() => {
        this.bookTheoryExamProductLoadingQueue--;
      });
  }

  // Theory exams
  protected theoryExamsCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected theoryExamsSuccess = false;
  protected theoryExamsError: Record<any, any> | null = null;
  private theoryExamsLoadingQueue = 0;
  protected theoryExamsData = [];
  protected theoryExamsTotalRow = 0;

  public get theoryExamsLoading() {
    return this.theoryExamsLoadingQueue > 0;
  }

  public async theoryExams(filter: any): Promise<void> {
    this.theoryExamsLoadingQueue++;
    this.theoryExamsSuccess = false;
    this.theoryExamsError = null;

    return await this.theoryExamsCancelableRequest
      .makeRequest({
        method: "post",
        url: `/theory-exam/filter`,
        data: filter,
      })
      .then((response: AxiosResponse) => {
        this.theoryExamsSuccess = true;
        this.theoryExamsData = response.data?.data || [];
        this.theoryExamsTotalRow = response.data?.total || 0;
      })
      .catch((error: AxiosError) => {
        this.theoryExamsError = error.response?.data;
      })
      .finally(() => {
        this.theoryExamsLoadingQueue--;
      });
  }

  // update participants Theory exams
  protected updateParticipantsTheoryExamsCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected updateParticipantsTheoryExamsSuccess = false;
  protected updateParticipantsTheoryExamsError: Record<any, any> | null = null;
  private updateParticipantsTheoryExamsLoadingQueue = 0;

  public get updateParticipantsTheoryExamsLoading() {
    return this.updateParticipantsTheoryExamsLoadingQueue > 0;
  }

  public async updateParticipantsTheoryExams(data: any): Promise<void> {
    this.updateParticipantsTheoryExamsLoadingQueue++;
    this.updateParticipantsTheoryExamsSuccess = false;
    this.updateParticipantsTheoryExamsError = null;

    return await this.updateParticipantsTheoryExamsCancelableRequest
      .makeRequest({
        method: "put",
        url: `/theory-exam/participants`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.updateParticipantsTheoryExamsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updateParticipantsTheoryExamsError = error.response?.data;
      })
      .finally(() => {
        this.updateParticipantsTheoryExamsLoadingQueue--;
      });
  }

  // Theory exams mark as ready
  protected readyTheoryExamCancelableRequest = createCancelableRequest(TheoryExamRequestMixin.name);

  protected readyTheoryExamSuccess = false;
  protected readyTheoryExamError: Record<any, any> | null = null;
  private readyTheoryExamLoadingQueue = 0;

  public get readyTheoryExamLoading() {
    return this.readyTheoryExamLoadingQueue > 0;
  }

  public async readyTheoryExam(theoryExamId: number): Promise<void> {
    this.readyTheoryExamLoadingQueue++;
    this.readyTheoryExamSuccess = false;
    this.readyTheoryExamError = null;

    return await this.readyTheoryExamCancelableRequest
      .makeRequest({
        method: "post",
        url: `/theory-exam/${theoryExamId}/mark-as-ready`,
      })
      .then((response: AxiosResponse) => {
        this.readyTheoryExamSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.readyTheoryExamError = error.response?.data;
      })
      .finally(() => {
        this.readyTheoryExamLoadingQueue--;
      });
  }
}
