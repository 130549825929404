











































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";
import { formatInstructorName, formatStudentName } from "@/utils/NameUtil";

const TheoryLessonModule = namespace("theory-lesson");

@Component({
  components: {
    FscHorizontalTable,
    FscSimpleCard,
  },
})
export default class EducationTheoryLessonPreview extends Vue {
  public name = "EducationTheoryLessonPreview";

  @TheoryLessonModule.Action("findOne")
  public findTheoryLessonById: any;

  @TheoryLessonModule.Getter("getDataItem")
  public lesson: any;

  @Prop()
  public theoryLesson!: any;

  @Prop()
  public student!: any;

  @Prop({ type: String })
  public createdByName!: string;

  public mounted(): void {
    if (this.theoryLesson.id) {
      this.findTheoryLessonById({
        id: this.theoryLesson.id,
        resource: "theory-lesson",
      });
    }
  }

  public get instructorName() {
    if (this.lesson.instructor) {
      return formatInstructorName(this.lesson.instructor.firstName, this.lesson.instructor.lastName);
    }
    return "";
  }

  public get theoryClassName() {
    if (this.lesson.theoryClass) {
      return `${this.lesson.theoryClass.name}`;
    }
    return "";
  }

  @Watch("theoryLesson")
  public onTheoryLessonIdChange(theory: any): void {
    if (theory) {
      this.findTheoryLessonById({
        id: theory.id,
        resource: "theory-lesson",
      });
    }
  }

  protected get studentFullName(): string {
    return this.student ? formatStudentName(this.student.firstName, this.student.lastName) : "";
  }

  protected sourceSignature: any = null;
  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "theoryClassName",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "time",
    },
    {
      label: this.$tc("general.duration", 1),
      key: "duration",
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "instructor",
    },
    {
      label: this.$t("general.signature"),
      key: "signature",
    },
  ];

  @Watch("lesson")
  public async onStudentDocumentChange(): Promise<void> {
    if (!this.lesson || !!this.lesson?.studentTheoryLessons === false) return;
    const studentId = this.student ? this.student.id : +this.$route.params?.id;
    const studentTheoryLesson = this.lesson.studentTheoryLessons.find((lesson: any) => lesson.studentId === studentId);
    if (!studentTheoryLesson?.signed || !studentTheoryLesson?.studentSignatureDocumentId) {
      this.sourceSignature = "";
      return;
    }
    return await axios({
      method: "get",
      url: `/student-documents/${studentTheoryLesson.studentSignatureDocumentId}`,
      responseType: "arraybuffer",
    })
      .then(
        (response: AxiosResponse) => {
          const mimeType = response.headers["content-type"].toLowerCase();
          const blob = response.data;
          this.sourceSignature =
            "data:" +
            mimeType +
            ";base64," +
            //@ts-ignore
            new Buffer(blob, "binary").toString("base64");
        },
        () => {
          console.log("Unavailable signature");
        }
      )
      .finally(() => {
        //
      });
  }
}
