














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import { FINAL_INVOICE } from "@/constants/EducationEventTypes";

@Component({
  components: { FscModal },
})
export default class CancelInvoiceModal extends Vue {
  public name = "CancelInvoiceModal";

  @Prop({ type: String, required: true })
  protected id!: string;

  @Prop()
  protected educationRow!: any;
}
