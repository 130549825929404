

























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import Validation from "@/components/Validation.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import find from "lodash/find";
import { namespace } from "vuex-class";
import { Validations } from "vuelidate-property-decorators";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import moment from "moment";

const StudentBookedProductModule = namespace("student-booked-product");
const CustomBookedProductModule = namespace("custom-booked-product");

@Component({
  components: {
    FscSimpleCard,
    FscInputDecimal,
    SaveButton,
    AbortButton,
    Validation,
    Datepicker,
  },
})
export default class EducationEventBookForm extends Vue {
  public name = "EducationEventBookForm";

  @Prop({ default: () => [] })
  public studentEducations!: any;

  @Prop({ default: () => [] })
  public vat!: any;

  protected formData: any = {
    studentEducation: {
      educationId: 0,
      licenseClass: null,
    },
    date: null,
    product: null,
    priceInEur: null,
    vat: null,
    freeText: null,
  };

  public onOptionDeselected(): void {
    this.formData.priceInEur = null;
    this.formData.vat = null;
  }

  public vatDisabled = false;
  public amountInEurDisabled = false;

  @Prop()
  public fetchEducationEvent!: any;

  @Prop()
  public radioSelected!: any;

  @Prop()
  public visibleBooking!: any;

  @Prop()
  public studentId!: any;

  @Prop({ default: () => [] })
  public prices!: any;

  @Prop({ type: Function })
  public fetchPricesByStudentEducation!: (educationId: number) => void;

  @StudentBookedProductModule.Action("create")
  public studentBookedProductCreate: any;

  @StudentBookedProductModule.Getter("getSuccess")
  public studentBookedProductSuccess: any;

  @StudentBookedProductModule.Getter("getIsLoading")
  public studentBookedProductLoading: any;

  @CustomBookedProductModule.Action("create")
  public customBookedProductCreate: any;

  @CustomBookedProductModule.Getter("getSuccess")
  public customBookedProductSuccess: any;

  @CustomBookedProductModule.Getter("getIsLoading")
  public customBookedProductLoading: any;

  @Validations()
  public validations(): Record<string, any> {
    return {
      formData: {
        studentEducation: {
          licenseClass: {
            required,
          },
        },
        date: { required },
        freeText: { required: requiredIf(() => !this.hasProduct), maxLength: maxLength(45) },
        vat: { required: requiredIf(() => !this.hasProduct) },
        priceInEur: { required: requiredIf(() => !this.hasProduct) },
      },
    };
  }

  public get freeTextLength() {
    if (this.formData?.freeText) {
      const length = this.formData?.freeText?.length || 0;
      return 45 - length > 0 ? 45 - length : 0;
    }
    return 45;
  }
  protected get hasProduct(): boolean {
    return Boolean(this.formData.product);
  }

  protected onSubmit(): void {
    this.onSubmitEducationBook();
  }

  protected onClose(): void {
    this.$emit("on-close");
  }

  protected onRemove(): void {
    this.onOptionDeselected();
  }

  public mounted() {
    this.$emit("on-mounted");
  }

  public onSelectStudentEducation(value: any) {
    if (value?.educationId) {
      this.fetchPricesByStudentEducation(value.educationId);
    }
  }

  protected async onSubmitEducationBook(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const educationId = this.formData.studentEducation?.educationId || null;

    if (this.formData.product) {
      await this.studentBookedProductCreate({
        resource: "student-booked-product",
        data: {
          priceInEur: this.formData.priceInEur,
          studentEducationId: educationId,
          product: this.formData.product,
          date: this.formData.date,
        },
      });
    } else {
      await this.customBookedProductCreate({
        resource: "custom-booking",
        data: {
          priceInEur: this.formData.priceInEur,
          studentEducationId: educationId,
          freeText: this.formData.freeText,
          date: this.formData.date,
          vat: this.formData.vat,
        },
      });
    }

    if (this.studentBookedProductSuccess || this.customBookedProductSuccess) {
      await this.fetchEducationEvent(this.studentId);

      this.formData.studentEducation = {
        educationId: 0,
        licenseClass: null,
      };
      this.formData.date = null;
      this.formData.product = null;
      this.formData.priceInEur = null;
      this.formData.vat = null;
      this.formData.freeText = null;

      this.$v.$reset();

      this.$emit("update:radioSelected", "");
      this.$emit("update:visibleBooking", false);
    }
  }

  // Default selection
  @Watch("studentEducations", { immediate: true })
  public onStudentEducationsChange(studentEd: any) {
    if (studentEd && studentEd.length === 1) {
      this.formData.studentEducation.educationId = studentEd[0].educationId;
      this.formData.studentEducation.licenseClass = studentEd[0].licenseClass;
      this.fetchPricesByStudentEducation(studentEd[0].educationId);
    }
  }

  @Watch("formData", { immediate: true, deep: true })
  public onChangeFormData(formData: any): void {
    if (formData.product) {
      const price = find(this.prices, (price: any) => {
        return formData.product.id === price.product.id;
      });
      this.formData.priceInEur = price.priceInEur;
      this.formData.vat = price.vat;
      this.formData.freeText = null;
      this.vatDisabled = true;
      this.amountInEurDisabled = true;
    } else {
      this.vatDisabled = false;
      this.amountInEurDisabled = false;
    }
  }

  @Watch("formData.studentEducation")
  public onChangeEducation(formDataEducation: any): void {
    if (formDataEducation) {
      this.formData.product = null;
      this.formData.priceInEur = null;
      this.formData.vat = null;
    }
  }

  protected get products(): Array<any> {
    return this.prices.map((price: any) => {
      return price.product;
    });
  }
  public get loading() {
    return this.studentBookedProductLoading || this.customBookedProductLoading;
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }
}
