import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IEmail } from "@/interfaces/IEmail";

const URL = "emails";

@Component
export default class EmailRequestMixin extends Vue {
  public email: IEmail | null = null;
  public emailLoading = false;
  public emailSuccess = false;
  public emailError: Record<any, any> | null = null;

  public async findEmail(emailId: number): Promise<void> {
    this.emailLoading = true;
    this.emailSuccess = false;
    this.emailError = null;

    return await axios
      .get(`${URL}/${emailId}`)
      .then((response: AxiosResponse) => {
        this.email = response.data;
        this.emailSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.emailError = error.response?.data;
      })
      .finally(() => {
        this.emailLoading = false;
      });
  }
}
