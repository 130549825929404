import { render, staticRenderFns } from "./DrivingLessonDetails.vue?vue&type=template&id=7986e973&"
import script from "./DrivingLessonDetails.vue?vue&type=script&lang=ts&"
export * from "./DrivingLessonDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports